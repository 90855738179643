import { ComponentStyleConfig, theme } from '@chakra-ui/react';
import colors from '../colors';

const TooltipStyles: ComponentStyleConfig = {
  baseStyle: {
    bgColor: colors.primary[300],
    color: 'white',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    fontSize: '0.875rem',
    borderRadius: 'none',
    lineHeight: '1.25rem',
    '--popper-arrow-bg': colors.primary[300],
    maxW: '10.5rem',
    p: '0.25rem 0.5rem',
    textAlign: 'center',
  },
  defaultProps: {},
};

export default TooltipStyles;
