import {
  FilterBuilder,
  Inclusion,
  Where,
  WhereBuilder,
} from '@loopback/filter';
import { AnyObject } from '@loopback/filter/dist/types';

export const PAGE_SIZE = 10;

export interface IRequestFilter {
  page?: number;
  pageSize?: number;
  order?: string[];
  searchValue?: string;
  where?: Where<AnyObject>;
}

export const buildSearchWhere = (searchValue: string, fieldKeys: string[]) => {
  const where = new WhereBuilder()
    .or(
      fieldKeys.map((key) => ({
        [key]: { ilike: `%${searchValue}%` },
      }))
    )
    .build();
  return {
    where,
    encoded: JSON.stringify(where),
  };
};

export const buildFilter = (
  { page, order, searchValue, pageSize, where }: IRequestFilter,
  searchFieldKeys?: string[],
  include?: string[] | Inclusion
) => {
  const filterBuilder = new FilterBuilder();
  let builder = filterBuilder;

  if (searchValue && searchFieldKeys) {
    builder = builder.where(
      buildSearchWhere(searchValue, searchFieldKeys).where
    );
  }

  if (where) {
    builder = builder.where(where);
  }

  if (page) {
    builder = builder
      .limit(pageSize ?? PAGE_SIZE)
      .offset((page - 1) * (pageSize ?? PAGE_SIZE));
  }

  if (order && order.length > 0) {
    builder = builder.order(order);
  }

  if (include) {
    builder = builder.include(include);
  }

  const filter = builder.build();
  return {
    filterBuilder: builder,
    encoded: JSON.stringify(filter),
  };
};
