'use client';

import { ChakraProvider } from '@chakra-ui/react';
import chakraTheme from '@/constants/theme';
import { FC } from 'react';

interface ChakraProvidersProps {
  children: React.ReactNode;
}
const ChakraProviders: FC<ChakraProvidersProps> = ({ children }) => {
  return <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>;
};

export default ChakraProviders;
