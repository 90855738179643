import { extendTheme } from '@chakra-ui/react';
import ButtonStyles from '@/constants/theme/components/ButtonStyles';
import colors from '@/constants/theme/colors';
import PopoverStyles from '@/constants/theme/components/PopoverStyles';
import CheckboxStyles from '@/constants/theme/components/CheckboxStyles';
import InputStyles from '@/constants/theme/components/InputStyles';
import ModalStyles from '@/constants/theme/components/ModalStyles';
import DrawerStyles from '@/constants/theme/components/DrawerStyles';
import DividerStyles from '@/constants/theme/components/DividerStyles';
import TabsStyles from '@/constants/theme/components/TabsStyles';
import TableStyles from '@/constants/theme/components/TableStyles';
import StepperStyles from '@/constants/theme/components/StepperStyles';
import RadioStyles from '@/constants/theme/components/RadioStyles';
import TooltipStyles from '@/constants/theme/components/TooltipStyles';
import SkeletonStyles from '@/constants/theme/components/SkeletonStyles';
import SliderStyles from '@/constants/theme/components/SliderStyles';
import SpinnerStyles from '@/constants/theme/components/SpinnerStyles';
import TextareaStyles from '@/constants/theme/components/TextareaStyles';

// const breakpoints = {
//   base: '0em', // 0px
//   sm: '30em', // ~480px. em is a relative unit and is dependant on the font size.
//   md: '48em', // ~768px
//   lg: '62em', // ~992px
//   xl: '80em', // ~1280px
//   '2xl': '96em', // ~1536px
// }

const chakraTheme = extendTheme({
  colors,
  styles: {
    global: {
      'html, body': {
        backgroundColor: colors.grey[50],
      },
    },
  },
  components: {
    Button: ButtonStyles,
    IconButton: ButtonStyles,
    Popover: PopoverStyles,
    Checkbox: CheckboxStyles,
    Input: InputStyles,
    Modal: ModalStyles,
    Drawer: DrawerStyles,
    Divider: DividerStyles,
    Tabs: TabsStyles,
    Table: TableStyles,
    Stepper: StepperStyles,
    Radio: RadioStyles,
    Tooltip: TooltipStyles,
    Skeleton: SkeletonStyles,
    Slider: SliderStyles,
    Spinner: SpinnerStyles,
    Textarea: TextareaStyles,
  },
});

export default chakraTheme;
