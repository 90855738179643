import colors from '@/constants/theme/colors';
import { ComponentStyleConfig } from '@chakra-ui/react';

const SpinnerStyles: ComponentStyleConfig = {
  baseStyle: {
    color: colors.primary[100],
  },
  defaultProps: {},
};

export default SpinnerStyles;
