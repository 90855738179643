'use client';

import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import { IProductItem } from '@/types/product';
import {
  useCurrencyCodeSelector,
  useCurrencyExchangeRateSelector,
} from '@/store/slices/app';
import { formatPrice } from '@/utils/product';

interface ProductPriceProps extends TextProps {
  priceValue: IProductItem['priceValue'];
  priceCurrency: IProductItem['priceCurrency'];
}
const ProductPrice: FC<ProductPriceProps> = ({
  priceValue,
  priceCurrency,
  ...textProps
}) => {
  // priceCurrency is fixed to USD
  const selectedCurrencyCode = useCurrencyCodeSelector();
  const currencyExchangeRate = useCurrencyExchangeRateSelector();

  const currency = selectedCurrencyCode ?? priceCurrency;
  const value = formatPrice(currency)(priceValue * currencyExchangeRate);

  return (
    <Text
      fontSize={{ base: '1rem', '2xl': '1.125rem' }}
      lineHeight="1.5rem"
      fontWeight="500"
      color="primary.100"
      textTransform="uppercase"
      {...textProps}
    >
      <Text as="span">{value}</Text>
    </Text>
  );
};

export default ProductPrice;
