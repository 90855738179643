import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);
import { parseDigitToDoubleDigit } from '@/utils/common';

export const getDurationFromDiff = (diff: number) => {
  const dayjsDuration = dayjs.duration(diff * 1000);
  const hours = Math.floor(dayjsDuration.asHours());
  const minutes = Math.floor(
    dayjsDuration.subtract(hours * 60, 'minute').asMinutes()
  );
  const seconds = Math.floor(
    dayjsDuration.subtract(hours * 60 * 60 + minutes * 60, 'second').asSeconds()
  );

  if (!!hours) {
    return `${parseDigitToDoubleDigit(hours)}:${parseDigitToDoubleDigit(
      minutes
    )}:${parseDigitToDoubleDigit(seconds)}`;
  }

  return `${parseDigitToDoubleDigit(minutes)}:${parseDigitToDoubleDigit(
    seconds
  )}`;
};

export const getTimerDeadlineInMinutes: (minutes: number) => Dayjs = (
  minutes = 15
) => {
  return dayjs().add(minutes, 'minute');
};
