import { ComponentStyleConfig } from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const SliderStyles: ComponentStyleConfig = {
  baseStyle: {
    container: {
    },
    track: {
      bgColor: colors.grey[300],
      h: '0.125rem',
    },
    thumb: {
      border: `0.25rem solid ${colors.primary[100]}`,
      borderColor: colors.primary[100],
      w: '1.125rem',
      h: '1.125rem',
    },
    filledTrack: {
      bgColor: colors.primary[100],
    },
    mark: {},
  },
  defaultProps: {},
};

export default SliderStyles;
