'use client';

import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { TimerLineIcon } from '@/components/Icons';
import { FC } from 'react';
import colors from '@/constants/theme/colors';
import { usePriceLockTimerDiffSelector } from '@/store/slices/app';
import { getDurationFromDiff } from '@/utils/day';

interface TimerDisplayProps extends FlexProps {}
const TimerDisplay: FC<TimerDisplayProps> = ({ ...flexProps }) => {
  const timeDiff = usePriceLockTimerDiffSelector();
  const time = getDurationFromDiff(timeDiff);

  return (
    <Flex alignItems="center" gap="0.25rem" userSelect="none" {...flexProps}>
      <TimerLineIcon size="1.25rem" color={colors.primary[25]} />
      <Text
        fontSize="0.875rem"
        lineHeight="1.25rem"
        color={colors.primary[100]}
      >
        {time}
      </Text>
    </Flex>
  );
};

export default TimerDisplay;
