import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const primaryVariant: SystemStyleInterpolation = {
  root: {},
  tab: {
    p: '0.75rem 1rem',
    fontSize: { base: '0.875rem', '2xl': '1rem' },
    fontWeight: '400',
    lineHeight: { base: '1.25rem', '2xl': '1.5rem' },
    minW: 'max-content',
    _selected: {
      boxShadow: `0px -3px 0px 0px ${colors.primary[100]} inset !important`,
    },
  },
  tablist: {
    overflow: 'auto',
    borderBottom: `1px solid ${colors.grey[200]}`,
  },
  tabpanels: {},
  tabpanel: {
    p: { base: '0', xl: '1.5rem 0 0' },
    maxW: '100%',
  },
};

const TabsStyles: ComponentStyleConfig = {
  baseStyle: {
    root: {},
    tab: {},
    tablist: {},
    tabpanels: {},
    tabpanel: {},
  },
  variants: {
    primary: primaryVariant,
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default TabsStyles;
