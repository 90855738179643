'use client';

import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import { IProductMetalType } from '@/types/product';

interface ProductMetalTypeProps extends TextProps {
  metalType: IProductMetalType;
}
const ProductMetalType: FC<ProductMetalTypeProps> = ({
  metalType,
  ...textProps
}) => {
  const color: { [key in IProductMetalType]: TextProps['color'] } = {
    [IProductMetalType.gold]: 'secondary.200',
    [IProductMetalType.platinum]: 'grey.500',
  };

  return (
    <Text
      fontSize={{ base: '0.75rem', '2xl': '0.875rem' }}
      fontWeight="300"
      lineHeight={{ base: '1rem', '2xl': '1.25rem' }}
      color={color[metalType]}
      {...textProps}
    >
      {metalType}
    </Text>
  );
};

export default ProductMetalType;
