import appApis from '@/store/apis/app';
import { IRequestFilter, buildFilter } from '@/utils/loopback-filter';
import { APP_QUERY_TAG } from '@/store/apis/app';
import { IAppProductVariant } from '@/types/api/app/product';
import { Where } from '@loopback/filter';
import { AnyObject } from '@loopback/filter/dist/types';

const appProductVariantsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    getProductVariants: build.query<
      IAppProductVariant[],
      IRequestFilter & {
        includeGiftCard?: boolean;
        uniqueGroupKey?: boolean;
      }
    >({
      query: ({
        includeGiftCard = false,
        uniqueGroupKey = true,
        ...filter
      }) => {
        return {
          url: '/product-variants',
          method: 'GET',
          params: {
            filter: buildFilter(filter, undefined, ['product']).encoded,
            includeGiftCard,
            uniqueGroupKey,
          },
        };
      },
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
      transformResponse: (response: IAppProductVariant[]) => {
        if (!response) return [];
        return response;
      },
    }),
    getGiftCardVariants: build.query<IAppProductVariant[], void>({
      query: () => {
        return {
          url: '/product-variants/gift-card',
          method: 'GET',
        };
      },
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
    }),
    getBestSellingProductVariants: build.query<
      IAppProductVariant[],
      IRequestFilter
    >({
      query: (filter) => ({
        url: '/product-variants/best-selling',
        method: 'GET',
        params: {
          filter: buildFilter(filter, undefined, ['product']).encoded,
        },
      }),
      providesTags: (result = []) => {
        if (!result) return [{ type: APP_QUERY_TAG.productVariants, id: '*' }];
        return [
          ...result.map(({ id }) => {
            return { type: APP_QUERY_TAG.productVariants, id } as const;
          }),
        ];
      },
    }),
    getProductVariantsCount: build.query<
      number,
      {
        where?: Where<AnyObject>;
        uniqueGroupKey?: boolean;
      }
    >({
      query: ({ where, uniqueGroupKey = true }) => {
        return {
          url: '/product-variants/count',
          method: 'GET',
          params: {
            where: JSON.stringify(where),
            includeGiftCard: false,
            uniqueGroupKey,
          },
        };
      },
      transformResponse: (response: { count: number }) => {
        if (!response) return 0;
        const { count } = response;
        return count;
      },
      providesTags: [APP_QUERY_TAG.productVariantsCount],
    }),
  }),
});

export const {
  useGetProductVariantsCountQuery,
  useGetProductVariantsQuery,
  useGetBestSellingProductVariantsQuery,
  useGetGiftCardVariantsQuery,
} = appProductVariantsApis;

export default appProductVariantsApis;
