import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';
import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle } = createMultiStyleConfigHelpers(drawerAnatomy.keys);

const mobileBottomMenuVariant: SystemStyleInterpolation = definePartsStyle(
  (props) => ({
    header: {
      borderBottom: 'none',
      p: 0,
      textAlign: 'center',
      fontSize: '1.125rem',
      fontWeight: '400',
      lineHeight: '1.5rem',
    },
    overlay: {},
    dialogContainer: {},
    dialog: {
      boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.08)',
      p: '1.5rem 1.25rem',
      borderRadius: '1.5rem 1.5rem 0rem 0rem',
    },
    closeButton: {
      top: '1.25rem',
      right: '1.25rem',
    },
    body: {},
    footer: {},
  })
);

const desktopVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  header: {
    borderBottom: 'none',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: colors.primary[300],
    p: '0',
  },
  overlay: {
    bgColor: 'transparent',
  },
  dialogContainer: {},
  dialog: {
    bgColor: colors.background,
    maxW: '22.5rem',
    boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.08)',
    p: '1.25rem',
    gap: '1.5rem',
  },
  closeButton: {
    top: '1.25rem',
    right: '1.25rem',
    w: '1.5rem',
    color: colors.primary[300],
  },
  body: {
    p: '0',
  },
  footer: {
    p: '0',
  },
}));

const mobileVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  header: {
    borderBottom: 'none',
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '400',
    lineHeight: '1.5rem',
    color: colors.primary[300],
    p: '0',
  },
  overlay: {
    bgColor: 'transparent',
  },
  dialogContainer: {},
  dialog: {
    bgColor: colors.background,
    maxW: 'none',
    p: '1.5rem 1.25rem',
    gap: '1.5rem',
  },
  closeButton: {
    top: '1.25rem',
    right: '1.25rem',
    w: '1.5rem',
    color: colors.primary[300],
  },
  body: {
    p: '0',
  },
  footer: {
    p: '0',
  },
}));

const mobileMenuVariant: SystemStyleInterpolation = definePartsStyle(
  (props) => ({
    header: {
      p: '0.75rem 1rem',
      borderBottom: `1px solid ${colors.grey[200]}`,
    },
    overlay: {},
    dialogContainer: {},
    dialog: {
      p: '0',
    },
    closeButton: {
      top: '1rem',
      right: '1rem',
      w: '1.5rem',
    },
    body: {
      p: '0',
    },
    footer: { p: '0' },
  })
);

const DrawerStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    mobileBottomMenu: mobileBottomMenuVariant,
    desktop: desktopVariant,
    mobile: mobileVariant,
    mobileMenu: mobileMenuVariant,
  },
  defaultProps: {
    variant: { base: 'mobile', lg: 'desktop' },
  },
};

export default DrawerStyles;
