'use client';

import { FC } from 'react';
import CartDrawer from '@/components/Cart/CartDrawer';
import ReduxProviders from '@/providers/ReduxProvider';

interface DrawersProps {}
const DrawersBase: FC<DrawersProps> = () => {
  return (
    <>
      <CartDrawer />
    </>
  );
};

const Drawers: FC<DrawersProps> = ({ ...props }) => {
  return (
    <ReduxProviders>
      <DrawersBase {...props} />
    </ReduxProviders>
  );
};

export default Drawers;
