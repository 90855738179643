import { ComponentStyleConfig } from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const RadioStyles: ComponentStyleConfig = {
  baseStyle: {
    control: {
      _checked: {
        background: colors.primary[100],
        borderColor: colors.primary[100],
        _hover: {
          background: colors.primary[100],
          borderColor: colors.primary[100],
        },
      },
      borderColor: colors.grey[300],
      borderWidth: '0.063rem',
      _hover: {
        borderColor: colors.primary[100],
      },
      width: '1.5rem',
      height: '1.5rem',
    },
    label: {},
    container: {},
  },
  defaultProps: {},
};

export default RadioStyles;
