import {
  Flex,
  Text,
  useBreakpointValue,
  Button,
  TextProps,
  FlexProps,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import Link from 'next/link';

interface EmptyStateProps {
  onAction?: () => void;
  title: string;
  titleProps?: TextProps;
  description: string;
  descriptionProps?: TextProps;

  action: string;
  actionLink?: string;
  renderIcon: () => ReactNode;

  containerProps?: FlexProps;
}
const EmptyState: FC<EmptyStateProps> = ({
  onAction,
  title,
  titleProps,
  description,
  descriptionProps,
  action,
  actionLink,
  renderIcon,
  containerProps,
}) => {
  const buttonVariant = useBreakpointValue({
    base: 'ghostSm',
    '2xl': 'ghostMd',
  });

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      py="2.5rem"
      gap="1.5rem"
      {...containerProps}
    >
      {renderIcon()}
      <Flex flexDir="column" alignItems="center" gap="0.75rem">
        <Text fontSize="1.125rem" lineHeight="1.5rem" {...titleProps}>
          {title}
        </Text>
        <Text
          color="grey.700"
          fontSize={{ base: '0.875rem', '2xl': '1rem' }}
          fontWeight="300"
          lineHeight={{ base: '1.25rem', '2xl': '1.5rem' }}
          textAlign="center"
          {...descriptionProps}
        >
          {description}
        </Text>
      </Flex>
      {!!actionLink ? (
        <Link href={actionLink}>
          <Button variant={buttonVariant}>{action}</Button>
        </Link>
      ) : (
        <Button variant={buttonVariant} onClick={onAction}>
          {action}
        </Button>
      )}
    </Flex>
  );
};

export default EmptyState;
