import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const mdVariant: SystemStyleInterpolation = {
  icon: {},
  control: {
    _checked: {
      background: colors.primary[100],
      borderColor: colors.primary[100],
      _hover: {
        background: colors.primary[100],
        borderColor: colors.primary[100],
      },
    },
    width: '1.25rem',
    height: '1.25rem',
    borderColor: colors.grey[300],
    borderWidth: '0.063rem',
    borderRadius: '0.25rem',
  },
  label: {
    _checked: {},
  },
  container: {},
};

const smVariant: SystemStyleInterpolation = {
  icon: {},
  control: {
    _checked: {
      background: colors.primary[100],
      borderColor: colors.primary[100],
      _hover: {
        background: colors.primary[100],
        borderColor: colors.primary[100],
      },
    },
    width: '1rem',
    height: '1rem',
    borderColor: colors.grey[300],
    borderWidth: '1px',
  },
  label: {
    _checked: {},
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  container: {},
};

const CheckboxStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    '2xl': mdVariant,
    sm: smVariant,
  },
  defaultProps: {
    variant: 'sm',
  },
};

export default CheckboxStyles;
