'use client';

import { IProductItem } from '@/types/product';
import { Text, TextProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ProductNameProps extends TextProps {
  productName: IProductItem['name'];
}
const ProductName: FC<ProductNameProps> = ({ productName, ...textProps }) => {
  return (
    <Text
      fontSize={{ base: '0.875rem', '2xl': '0.938rem' }}
      lineHeight="1.25rem"
      fontWeight="300"
      color="grey.900"
      {...textProps}
    >
      {productName}
    </Text>
  );
};

export default ProductName;
