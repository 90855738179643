import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';
import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const primaryVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    display: 'table',
    _hover: {
      overflow: 'auto',
    },
    width: '100%',
  },
  thead: {
    position: 'sticky',
    bgColor: colors.grey[50],
    zIndex: 10,
    top: 0,
    tr: {
      zIndex: 10,
      bgColor: colors.grey[50],
      th: {
        borderTop: `1px solid ${colors.primary[50]}`,
        borderBottom: `1px solid ${colors.grey[200]}`,
        borderLeft: 'none',
        borderRight: 'none',
        _first: {
          borderLeft: `1px solid ${colors.primary[50]}`,
        },
        _last: {
          borderRight: `1px solid ${colors.primary[50]}`,
        },
        fontSize: {
          base: '0.75rem',
          xl: '0.875rem',
          '2xl': '0.9375rem',
        },
        fontWeight: 400,
        lineHeight: '1.5rem',
        color: colors.grey[900],
        textTransform: 'capitalize',
        w: 'max-content',
        whiteSpace: 'nowrap',
      },
    },
  },
  tbody: {
    w: '100%',
    tr: {
      td: {
        fontSize: {
          base: '0.75rem',
          xl: '0.875rem',
          '2xl': '0.9375rem',
        },
        fontWeight: '400',
        lineHeight: '1.25rem',
        color: colors.grey[900],
        bgColor: colors.white,

        borderBottom: `1px solid ${colors.grey[200]}`,
        _first: {
          borderLeft: `1px solid ${colors.primary[50]}`,
        },
        _last: {
          borderRight: `1px solid ${colors.primary[50]}`,
        },
      },
      _first: {},
      _last: {
        td: {
          _first: {},
          _last: {},
        },
      },
      _notLast: {},
    },
  },
}));

const TableStyles: ComponentStyleConfig = {
  baseStyle: {
    th: {
      _hover: {},
    },
    tr: {},
    td: {},
  },
  variants: {
    primary: primaryVariant,
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default TableStyles;
