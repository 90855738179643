import {
  RiShoppingCart2Line,
  RiUserLine,
  RiSearchLine,
  RiArrowDownSLine,
  RiArrowRightLine,
  RiArrowUpLine,
  RiArrowDownLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiHeartLine,
  RiFilterLine,
  RiCloseLine,
  RiArrowUpSLine,
  RiErrorWarningLine,
  RiSubtractLine,
  RiEyeLine,
  RiEyeOffLine,
  RiCheckboxCircleLine,
  RiCheckboxCircleFill,
  RiCheckLine,
  RiMenuLine,
  RiChat3Line,
  RiArrowLeftLine,
  RiQuestionLine,
  RiEditLine,
  RiLockLine,
  RiMoreLine,
  RiAddLine,
  RiShoppingCart2Fill,
  RiTimerLine,
  RiUploadLine,
  RiLayoutLeftFill,
  RiArchiveFill,
  RiUserFill,
  RiHeartFill,
} from 'react-icons/ri';
import { FcGoogle } from 'react-icons/fc';
import { IoMdCloseCircle } from 'react-icons/io';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { AiOutlineGold, AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import { BiRecycle } from 'react-icons/bi';
import { MdMailOutline, MdWhatsapp } from 'react-icons/md';
import { FiPackage } from 'react-icons/fi';
import { IoTriangleSharp } from 'react-icons/io5';

export const SearchIcon = RiSearchLine;
export const ShoppingCartIcon = RiShoppingCart2Line;
export const ShoppingCartFillIcon = RiShoppingCart2Fill;
export const UserIcon = RiUserLine;
export const UserFillIcon = RiUserFill;
export const ArrowDownSLineIcon = RiArrowDownSLine;
export const ArrowRightLineIcon = RiArrowRightLine;
export const ArrowLeftSLineIcon = RiArrowLeftSLine;
export const ArrowRightSLineIcon = RiArrowRightSLine;
export const HeartLineIcon = RiHeartLine;
export const HeartFillIcon = RiHeartFill;
export const FilterLineIcon = RiFilterLine;
export const CloseLineIcon = RiCloseLine;
export const ArrowUpSLine = RiArrowUpSLine;
export const ArrowUpLineIcon = RiArrowUpLine;
export const ArrowDownLineIcon = RiArrowDownLine;
export const CircleFilledCloseIcon = IoMdCloseCircle;
export const ErrorWarningLineIcon = RiErrorWarningLine;
export const SubtractLineIcon = RiSubtractLine;
export const EyeLineIcon = RiEyeLine;
export const EyeOffLineIcon = RiEyeOffLine;
export const ColorGoogleIcon = FcGoogle;
export const CheckboxCircleLineIcon = RiCheckboxCircleLine;
export const CheckboxCircleFillIcon = RiCheckboxCircleFill;
export const MenuLineIcon = RiMenuLine;
export const Chat3LineIcon = RiChat3Line;
export const ArrowLeftLineIcon = RiArrowLeftLine;
export const QuestionLineIcon = RiQuestionLine;
export const EditLineIcon = RiEditLine;
export const LockLineIcon = RiLockLine;
export const CheckLineIcon = RiCheckLine;
export const MoreLineIcon = RiMoreLine;
export const AddLineIcon = RiAddLine;
export const TimerLineIcon = RiTimerLine;
export const UploadLineIcon = RiUploadLine;
export const LayoutLeftFillIcon = RiLayoutLeftFill;
export const ArchiveFillIcon = RiArchiveFill;
export const ShippingIcon = LiaShippingFastSolid;
export const OutlineGoldIcon = AiOutlineGold;
export const OutlinePlusIcon = AiOutlinePlus;
export const OutlineMinusIcon = AiOutlineMinus;
export const RecycleIcon = BiRecycle;
export const MailOutlineIcon = MdMailOutline;
export const WhatsappIcon = MdWhatsapp;
export const PackageIcon = FiPackage;
export const TriangleSharpIcon = IoTriangleSharp;

export const buttonIconSizeSm = '1rem';
export const buttonIconSizeMd = '1.25rem';
export const buttonIconSizeLg = '1.25rem';
