'use client';

import { Box, Flex } from '@chakra-ui/react';
import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { FooterProps } from '@/components/Layout/Footer/Footer';
import { AnimatePresence, motion } from 'framer-motion';
import { usePathname } from 'next/navigation';
import colors from '@/constants/theme/colors';
import { HeaderProps } from './Header/Header';
import Drawers from './Drawers';

import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('./Footer/Footer'));
const AppInit = dynamic(() => import('./AppInit'), {
  ssr: false,
});
const Modals = dynamic(() => import('./Modals/Modals'));
const Header = dynamic(() => import('./Header/Header'), {
  loading: () => <Box w="100%" h={{ base: '3.5rem', xl: '5rem' }}></Box>,
});
const Banner = dynamic(() => import('./Header/Banner'), {
  ssr: false,
  loading: () => <Box w="100%" bgColor={colors.primary[100]} h="2rem"></Box>,
});

export interface LayoutProps
  extends PropsWithChildren,
    HeaderProps,
    FooterProps {
  banners?: {
    textToDisplay: string[];
    hasCountDownClock: boolean;
    clockEndAt: Date;
  }[];
  hasValidToken?: boolean;
  role?: string;
  authStatus?: string;
  isDeviceMobile?: boolean;
}

const Layout: FC<LayoutProps> = ({
  children,
  banners = [],
  hasValidToken,
  role,
  authStatus,
  faqs,

  ...headerProps
}) => {
  const pathname = usePathname();
  const pageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!window) return;
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Drawers />
      <Modals />
      <AppInit
        hasValidToken={hasValidToken}
        role={role}
        authStatus={authStatus}
      />
      <Flex
        flexDir="column"
        alignItems="center"
        maxW="100vw"
        ref={pageRef}
        bgColor={colors.background}
      >
        {/* 3.5rem + 2rem */}
        {/* 5rem + 2rem */}
        <Box w="100%" h={{ base: '5.5rem', xl: '7rem' }}>
          <Header {...headerProps}>
            <Flex w="100%" flexDir="column" minH="2rem">
              {banners.map(({ textToDisplay }, idx) => (
                <Banner key={`${textToDisplay}_${idx}`} texts={textToDisplay} />
              ))}
            </Flex>
          </Header>
        </Box>
        <AnimatePresence mode="wait" initial={false}>
          <motion.div
            style={{
              flex: 1,
              width: '100%',
            }}
            key={pathname}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
          >
            {children}
          </motion.div>
        </AnimatePresence>
        <Footer faqs={faqs} />
      </Flex>
    </>
  );
};

export default Layout;
