export const parseDigitToDoubleDigit = (digit: number) => {
  return digit.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const getItemsChunks = <T>(items: T[], perChunk?: number): T[][] =>
  items.reduce((resultArray: T[][], item, index) => {
    const chunkIndex = Math.floor(index / (perChunk ?? 4));

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const displayNumberWithSign = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    signDisplay: 'exceptZero',
    maximumFractionDigits: 2,
  }).format(num);
};
