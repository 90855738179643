import colors from '@/constants/theme/colors';
import { ComponentStyleConfig, theme } from '@chakra-ui/react';
import { defineStyle, defineStyleConfig, cssVar } from '@chakra-ui/react';

const $startColor = cssVar('skeleton-start-color');
const $endColor = cssVar('skeleton-end-color');

const SkeletonStyles: ComponentStyleConfig = {
  baseStyle: {
    _light: {
      [$startColor.variable]: colors.grey[200],
      [$endColor.variable]: colors.grey[100],
    },
    _dark: {
      [$startColor.variable]: 'colors.red.800',
      [$endColor.variable]: 'colors.red.600',
    },
  },
  defaultProps: {},
};

export default SkeletonStyles;
