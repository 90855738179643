import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
  theme,
  defineStyle,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const commonFieldStyles: SystemStyleInterpolation = {
  borderRadius: 'none',
  border: `1px solid ${colors.grey[200]}`,
  borderColor: colors.grey[200],
  color: colors.primary[300],
  fontWeight: '300',
  bgColor: colors.white,
  _hover: {
    borderColor: colors.primary[25],
  },
  _active: {
    borderColor: colors.primary[25],
  },
  _focus: {
    borderColor: colors.primary[25],
  },
  _placeholder: {
    color: colors.grey[400],
  },
  _invalid: {
    borderColor: colors.red[200],
  },
  _disabled: {
    opacity: '1',
    borderColor: colors.grey[200],
    bgColor: colors.grey[100],
    color: colors.grey[500],

    _hover: {
      borderColor: colors.grey[200],
      bgColor: colors.grey[100],
    },
  },
  _autofill: {},
};

const mdVariant: SystemStyleInterpolation = defineStyle({
  ...theme.components.Textarea.variants?.outline,
  ...commonFieldStyles,
  fontSize: '1rem',
  boxSizing: 'border-box',
  lineHeight: '1.5rem',
});

const smVariant: SystemStyleInterpolation = defineStyle({
  ...theme.components.Textarea.variants?.outline,
  ...commonFieldStyles,

  fontSize: '0.9375rem',
  boxSizing: 'border-box',
  lineHeight: '1.25rem',
});

export const TextareaStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    md: mdVariant,
    sm: smVariant,
  },
  defaultProps: {
    variant: { base: 'sm', '2xl': 'md' },
  },
};

export default TextareaStyles;
