import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
  theme,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const commonFieldStyles: SystemStyleInterpolation = {
  borderRadius: 'none',
  border: `1px solid ${colors.grey[200]}`,
  borderColor: colors.grey[200],
  color: colors.primary[300],
  fontWeight: '300',
  bgColor: colors.white,
  _hover: {
    borderColor: colors.primary[25],
  },
  _active: {
    borderColor: colors.primary[25],
  },
  _focus: {
    borderColor: colors.primary[25],
  },
  _placeholder: {
    color: colors.grey[400],
  },
  _invalid: {
    borderColor: colors.red[200],
  },
  _disabled: {
    opacity: '1',
    borderColor: colors.grey[200],
    bgColor: colors.grey[100],
    color: colors.grey[400],

    _hover: {
      borderColor: colors.grey[200],
    },
  },
  _autofill: {},
};

const mdVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  addon: {},
  field: {
    ...theme.components.Input.variants?.outline(props).field,
    ...commonFieldStyles,

    height: '2.75rem',
    fontSize: '1rem',
    boxSizing: 'border-box',
    lineHeight: '1.25rem',
  },
  element: {
    height: '100%',
    width: 'max-content',
    px: '1rem',
  },
}));

const smVariant: SystemStyleInterpolation = definePartsStyle((props) => ({
  addon: {},
  field: {
    ...theme.components.Input.variants?.outline(props).field,
    ...commonFieldStyles,

    fontSize: '1rem',
    boxSizing: 'border-box',
    lineHeight: '1.25rem',
    height: '2.5rem',
  },
  element: {
    height: '100%',
    width: 'max-content',
    px: '1rem',
  },
}));

export const InputStyles: ComponentStyleConfig = {
  baseStyle: {
    addon: {},
    field: {},
    element: {},
  },
  variants: {
    md: mdVariant,
    sm: smVariant,
  },
  defaultProps: {
    variant: { base: 'sm', '2xl': 'md' },
  },
};

export default InputStyles;
