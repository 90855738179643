'use client';

import { ArchiveFillIcon } from '@/components/Icons';
import ProductMetalType from '@/components/Product/ProductItem/ProductMetalType';
import ProductName from '@/components/Product/ProductItem/ProductName';
import ProductPrice from '@/components/Product/ProductItem/ProductPrice';
import ProductThumbnail from '@/components/Product/ProductItem/ProductThumbnail';
import Title from '@/components/UI/Title';
import colors from '@/constants/theme/colors';
import { useGetProductVariantsQuery } from '@/store/apis/app.product-variants';
import { StockAvailability } from '@/types/api/app/product';
import { ICartItem } from '@/types/cart';
import { IProductItem } from '@/types/product';
import {
  getProductMetalType,
  mapAppProductVariantToProductItem,
} from '@/utils/product';
import {
  ModalProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Button,
  Spinner,
  Grid,
  GridItem,
  FlexProps,
} from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { CloseLineIcon } from '@/components/Icons';
import { useDispatch } from 'react-redux';
import { appActions, useCartItemsSelector } from '@/store/slices/app';
import {
  useAddCartItemMutation,
  useDeleteCartItemMutation,
} from '@/store/apis/app.carts';
import { useIsLoggedInSelector } from '@/store/slices/user';

interface OutOfStockModalProps extends Omit<ModalProps, 'children'> {
  onCheckout?: (cartItems: ICartItem[]) => void;
}
const OutOfStockModal: FC<OutOfStockModalProps> = ({
  onCheckout,
  ...modalProps
}) => {
  const { onClose, isOpen } = modalProps;
  const dispatch = useDispatch();
  const [addCartItem] = useAddCartItemMutation();
  const [deleteCartItem] = useDeleteCartItemMutation();
  const isLoggedIn = useIsLoggedInSelector();
  const cartItems = useCartItemsSelector();

  const outOfStockItems = cartItems.filter(
    ({ stockAvailability, productVariant }) =>
      stockAvailability === StockAvailability.OutOfStock ||
      productVariant?.stockAvailability === StockAvailability.OutOfStock
  );

  const {
    data: productVariants = [],
    isLoading: isGetProductVariantsLoading,
    isFetching: isGetProductVariantsFetching,
  } = useGetProductVariantsQuery(
    {
      page: 1,
      pageSize: 3,
      where: {
        stockAvailability: {
          ilike: StockAvailability.InStock,
        },
      },
    },
    {
      skip: !isOpen,
    }
  );

  const [selectedProductItem, setSelectedProductItem] =
    useState<IProductItem | null>();
  const isLoading = isGetProductVariantsLoading || isGetProductVariantsFetching;
  const productItems = productVariants.map(mapAppProductVariantToProductItem);

  const outOfStockItemsDisplay = outOfStockItems
    .map(
      ({ productVariant, product }) =>
        `${product.title} - ${getProductMetalType(productVariant.metal)}, ${
          productVariant.size
        }`
    )
    .join(';');

  const handleCheckout = useCallback(async () => {
    onClose();
    await Promise.all(
      outOfStockItems.map(async (cartItem) => {
        const { productVariant } = cartItem;
        if (isLoggedIn) {
          await deleteCartItem({
            body: {},
            params: {
              cartItemId: cartItem.id,
            },
          });
        }
        dispatch(appActions.removeCartItem({ cartProductItem: cartItem }));
      })
    );

    if (!selectedProductItem) {
      const updatedCartItems = cartItems.filter(
        ({ stockAvailability, productVariant }) =>
          !(
            stockAvailability === StockAvailability.OutOfStock ||
            productVariant.stockAvailability === StockAvailability.OutOfStock
          )
      );
      onCheckout?.(updatedCartItems);
      return;
    }

    const { productVariantId, product, productVariant } = selectedProductItem;
    if (isLoggedIn) {
      await addCartItem({
        body: {
          productVariantId,
          quantity: 1,
        },
      });
    }

    if (!product || !productVariant) return;
    dispatch(
      appActions.addCartItem({
        cartProductItem: {
          product,
          productVariant,
        },
      })
    );
    const updatedCartItems = [
      ...cartItems,
      {
        product,
        productVariant,
        quantity: 1,
      } as ICartItem,
    ].filter(
      ({ stockAvailability, productVariant }) =>
        !(
          stockAvailability === StockAvailability.OutOfStock ||
          productVariant.stockAvailability === StockAvailability.OutOfStock
        )
    );
    onCheckout?.(updatedCartItems);
  }, [
    selectedProductItem,
    outOfStockItems,
    isLoggedIn,
    onCheckout,
    onClose,
    cartItems,
    addCartItem,
    deleteCartItem,
    dispatch,
  ]);

  const displayProductItems = useMemo(() => {
    return productItems.filter(({ productVariantId }) => {
      const inCartVariantIds = cartItems.map(
        ({ productVariant }) => productVariant.id
      );

      return !inCartVariantIds.includes(productVariantId);
    });
  }, [productItems, cartItems]);

  return (
    <Modal {...modalProps}>
      <ModalOverlay />
      <ModalContent pt="2.5rem !important">
        <ModalBody>
          <Flex flexDir="column" gap="2.25rem">
            <Flex flexDir="column" alignItems="center" gap="1.5rem">
              <ArchiveFillIcon size="2rem" color={colors.secondary[50]} />
              <Flex flexDir="column" alignItems="center" gap="0.75rem">
                <Title
                  textAlign="center"
                  fontSize="1.125rem"
                  lineHeight="1.5rem"
                >
                  Out of Stock Item in Your Cart
                </Title>
                <Text
                  textAlign="center"
                  fontSize="0.9375rem"
                  fontWeight="300"
                  lineHeight="1.25rem"
                  color="grey.700"
                >
                  We{"'"}re sorry, but one of the items in your cart (
                  {outOfStockItemsDisplay}) is currently out of stock. We
                  recommend some similar product below or you can proceed with
                  the checkout process without this item.
                </Text>
              </Flex>
            </Flex>
            <Flex flexDir="column" gap="1rem" alignItems="center">
              <Title
                textAlign="center"
                color="grey.700"
                fontSize="0.9375rem"
                lineHeight="1.25rem"
              >
                Select Similar Product
              </Title>
              {isLoading ? (
                <Spinner size="sm" />
              ) : !displayProductItems.length ? (
                <Text
                  textAlign="center"
                  fontSize="0.9375rem"
                  fontWeight="300"
                  lineHeight="1.25rem"
                  color="grey.700"
                >
                  Nothing to show
                </Text>
              ) : (
                <Grid templateColumns="repeat(3, 1fr)" gap="0.5rem" w="100%">
                  {displayProductItems.map((prodItem) => {
                    const isSelected =
                      !!selectedProductItem &&
                      selectedProductItem.productVariantId ===
                        prodItem.productVariantId;

                    return (
                      <GridItem key={prodItem.productVariantId}>
                        <SimilarItem
                          productItem={prodItem}
                          isSelected={isSelected}
                          onSelect={() => setSelectedProductItem(prodItem)}
                          onDeselect={() => setSelectedProductItem(null)}
                        />
                      </GridItem>
                    );
                  })}
                </Grid>
              )}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex alignItems="center" gap="0.75rem" w="100%">
            <Button variant="secondaryMd" flex="1" onClick={onClose}>
              Back to Cart
            </Button>
            <Button
              variant="secondaryMd"
              flex="1"
              w="max-content"
              maxW="none"
              onClick={handleCheckout}
            >
              {!!selectedProductItem ? 'Checkout' : 'Checkout without item'}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface SimilarItemProps {
  productItem: IProductItem;
  isSelected?: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
}
const SimilarItem: FC<SimilarItemProps> = ({
  productItem,
  isSelected,
  onSelect,
  onDeselect,
}) => {
  const {
    name,
    metalType,
    thumbnailUrl,
    priceValue,
    priceCurrency,
    productVariant,
  } = productItem;

  const handleRemove: FlexProps['onClick'] = (ev) => {
    ev.stopPropagation();
    onDeselect?.();
  };

  return (
    <Flex
      flexDir="column"
      cursor="pointer"
      p="0.5rem"
      position="relative"
      borderRadius="0.5rem"
      border={`1px solid ${isSelected ? colors.primary[50] : 'transparent'}`}
      userSelect="none"
      onClick={onSelect}
    >
      {isSelected && (
        <Flex
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top="-0.5rem"
          right="-0.5rem"
          zIndex="10"
          bgColor="primary.100"
          w="1rem"
          aspectRatio="1/1"
          borderRadius="full"
          cursor="pointer"
          onClick={handleRemove}
        >
          <CloseLineIcon size="1rem" color="white" />
        </Flex>
      )}
      <ProductThumbnail
        thumbnailUrl={thumbnailUrl}
        thumbnailDisplayMode="square"
        name={name}
      />
      <Flex flexDir="column" p="0.5rem" gap="0.5rem">
        <Flex
          flexDir="column"
          gap="0.25rem"
          fontSize="0.75rem"
          lineHeight="1rem"
        >
          <ProductMetalType metalType={metalType} />
          <ProductName
            productName={name}
            fontSize="0.875rem"
            fontWeight="400"
            lineHeight="1.25rem"
          />
        </Flex>
        <ProductPrice
          priceValue={priceValue}
          priceCurrency={priceCurrency}
          fontSize="1rem"
          lineHeight="1.5rem"
        />
      </Flex>
    </Flex>
  );
};

export default OutOfStockModal;
