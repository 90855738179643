import {
  Flex,
  Button,
  Text,
  ToastProps as ChakraToastProps,
  FlexProps,
  ButtonProps,
} from '@chakra-ui/react';
import { FC, useMemo } from 'react';
import { CheckLineIcon } from '@/components/Icons';

type ToastProps = {
  message: string;
  status: ChakraToastProps['status'];
  onAction?: () => void;
  action?: string;
};

const Toast: FC<ToastProps> = ({ message, status, onAction, action }) => {
  const bgColor: FlexProps['bgColor'] = useMemo(() => {
    switch (status) {
      case 'error':
        return 'red.200';
      case 'success':
        return 'primary.100';
      case 'warning':
        return 'yellow.200';
      case 'loading':
      case 'info':
      default:
        return 'grey.800';
    }
  }, [status]);

  const color: FlexProps['color'] = useMemo(() => {
    switch (status) {
      case 'warning':
        return 'grey.800';
      case 'info':
      case 'error':
      case 'success':
      case 'loading':
      default:
        return 'white';
    }
  }, [status]);

  const buttonVariant: ButtonProps['variant'] = useMemo(() => {
    switch (status) {
      case 'warning':
        return 'textSecondarySm';
      case 'info':
      case 'error':
      case 'success':
      case 'loading':
      default:
        return 'textDarkSm';
    }
  }, [status]);

  return (
    <Flex
      p="1rem"
      w={{ base: '80vw', lg: '37.5rem' }}
      alignItems="center"
      bgColor={bgColor}
      color={color}
      gap="0.5rem"
      boxShadow="0px 16px 40px 0px rgba(0, 0, 0, 0.22)"
      borderRadius="0.125rem"
    >
      <CheckLineIcon size="1rem" />
      <Text fontSize="0.875rem" flex="1" lineHeight="1.25rem">
        {message}
      </Text>
      {!!action && (
        <Button
          variant={buttonVariant}
          color={color}
          onClick={onAction}
          p="0"
          borderBottom={`1px solid ${color}`}
        >
          {action}
        </Button>
      )}
    </Flex>
  );
};

export default Toast;
