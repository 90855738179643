'use client';

import { LayoutProps } from '@/components/Layout/Layout';
import Providers from '@/providers';
import { FC, PropsWithChildren } from 'react';
import { usePathname } from 'next/navigation';
import { PATH } from '@/constants/paths';
import Layout from '@/components/Layout/Layout';

interface LayoutWrapperProps extends PropsWithChildren, LayoutProps {}
const LayoutWrapper: FC<LayoutWrapperProps> = ({
  children,
  ...layoutProps
}) => {
  const pathname = usePathname();

  const toHideLayout = pathname === PATH.landing;

  if (toHideLayout) return <Providers>{children}</Providers>;

  return (
    <Providers>
      <Layout {...layoutProps}>{children}</Layout>
    </Providers>
  );
};

export default LayoutWrapper;
