'use client';

import { FC } from 'react';
import ChakraProviders from '@/providers/ChakraProvider';

interface ProvidersProps {
  children: React.ReactNode;
}
const Providers: FC<ProvidersProps> = ({ children }) => {
  return <ChakraProviders>{children}</ChakraProviders>;
};

export default Providers;
