import { ComponentStyleConfig } from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const DividerStyles: ComponentStyleConfig = {
  baseStyle: {
    borderColor: colors.grey[200],
  },
  defaultProps: {},
};

export default DividerStyles;
