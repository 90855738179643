'use client';

import Toast from '@/components/UI/Toast';
import {
  useToast as chakraUseToast,
  ToastProps,
  UseToastOptions,
} from '@chakra-ui/react';

const useToast = (options?: UseToastOptions) => {
  const toast = chakraUseToast({
    duration: 3000,
    isClosable: true,
    containerStyle: {
      marginBottom: { base: '1rem', lg: '3.75rem' },
    },
    ...options,
  });

  return (
    message: string,
    status: ToastProps['status'],
    action?: string,
    onAction?: () => void
  ) =>
    toast({
      render: (props) => {
        const handleAction = () => {
          props.onClose();
          onAction?.();
        };
        return (
          <Toast
            message={message}
            status={status}
            onAction={handleAction}
            action={action}
          />
        );
      },
    });
};

export default useToast;
