import {
  ComponentStyleConfig,
  SystemStyleInterpolation,
  theme,
} from '@chakra-ui/react';
import colors from '@/constants/theme/colors';

const primaryVariant: SystemStyleInterpolation = {
  stepper: {
    gap: '2px',
  },
  step: {
    gap: 0,
    '&[data-status="complete"]': {
      gap: 0,
    },
    '&[data-status="active"]': {
      gap: 0,
    },
    '&[data-status="incomplete"]': {},
  },
  title: {
    w: 'max-content',
    textAlign: 'center',
    color: colors.grey[900],

    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  },
  description: {
    w: 'max-content',
    textAlign: 'center',
    color: colors.grey[500],

    fontSize: '0.75rem',
    fontWeight: '300',
    lineHeight: '1rem',
  },
  indicator: {
    width: '0.5rem',
    height: '0.5rem',
    '&[data-status="complete"]': {
      bgColor: colors.primary[100],
      borderColor: colors.primary[100],
    },
    '&[data-status="active"]': {
      bgColor: colors.primary[100],
      borderColor: colors.primary[100],
    },
    '&[data-status="incomplete"]': {
      bgColor: colors.primary[50],
      borderColor: colors.primary[50],
    },
  },
  separator: {
    '&[data-status="complete"]': {
      bgColor: colors.primary[100],
      marginLeft: '2px',
    },
    '&[data-status="active"]': {
      bgColor: colors.primary[50],
      marginLeft: '2px',
    },
    '&[data-status="incomplete"]': {
      bgColor: colors.primary[50],
      marginLeft: '2px',
    },
  },
  icon: {
    '[data-status="complete"]': {},
    '&[data-status="active"]': {},
    '&[data-status="incomplete"]': {},
  },
  number: {},
};

export const StepperStyles: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    primary: primaryVariant,
  },
  defaultProps: {
    variant: 'primary',
  },
};

export default StepperStyles;
