'use client';
import { Box, BoxProps } from '@chakra-ui/react';
import Image from 'next/image';
import { FC, useMemo } from 'react';
import { IProductItem } from '@/types/product';
import { IProductImageDisplayMode } from './types';

interface ProductThumbnailProps {
  thumbnailUrl: IProductItem['thumbnailUrl'];
  thumbnailDisplayMode: IProductImageDisplayMode;
  name: IProductItem['name'];
}

const ProductThumbnail: FC<ProductThumbnailProps> = ({
  thumbnailUrl,
  thumbnailDisplayMode,
  name,
}) => {
  const imageSize: Pick<BoxProps, 'w' | 'aspectRatio'> = useMemo<
    Pick<BoxProps, 'w' | 'aspectRatio'>
  >(() => {
    switch (thumbnailDisplayMode) {
      case 'square': {
        return { w: '100%', aspectRatio: '1/1' };
      }

      case 'horizontal': {
        return { w: '100%', aspectRatio: '9/15' };
      }

      default: {
        return {
          w: '100%',
          aspectRatio: '1/1',
        };
      }
    }
  }, [thumbnailDisplayMode]);

  return (
    <Box
      {...imageSize}
      borderRadius="0.25rem"
      position="relative"
      overflow="hidden"
    >
      <Image
        alt={name}
        src={thumbnailUrl}
        fill
        sizes="(max-width: 992px) 175px, 270px"
        style={{ objectFit: 'cover' }}
        priority
        unoptimized
      />
    </Box>
  );
};

export default ProductThumbnail;
